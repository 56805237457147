<template>
    <div class="login">
        <div class="main">
            <div class="mainHeader">
                <div>
                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                        style="width:100%;height:100%"></el-avatar>
                </div>
                <div>
                    <div>峨眉旅业维修管理系统</div>
                    <div>Emei tourism maintenance management system</div>
                </div>
            </div>
            <div class="mainCentent">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="name">
                        <el-input type="text" v-model="ruleForm.name" autocomplete="off"
                            prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <div class="loginBtn">
                        <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                // if (this.ruleForm.checkPass !== '') {
                //     this.$refs.ruleForm.validateField('checkPass');
                // }
                callback();
            }
        };
        let validateName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入用户名'));
            } else {
                // if (this.ruleForm.checkPass !== '') {
                //     this.$refs.ruleForm.validateField('checkPass');
                // }
                callback();
            }
        }
        return {
            ruleForm: {
                name: '',
                pass: ''
            },
            rules: {
                name: [
                    { validator: validateName, trigger: 'blur' }
                ],
                pass: [
                    { validator: validatePass, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$router.push({ name: 'VehiclesApproach', replace: true })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="less" scoped>
.login {
    width: 100%;
    height: 100vh;
    background-color: #169deb;
    display: flex;
    justify-content: center;
    align-items: center;

    .main {
        width: 400px;
        height: 320px;
        display: flex;
        flex-direction: column;

        .mainHeader {
            width: 100%;
            height: 80px;
            display: flex;
            align-items: center;
            padding: 0px 20px;

            &>div:first-child {
                width: 60px;
                height: 60px;
            }

            &>div:last-child {
                flex: 1 0 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 25px;

                &>div:last-child {
                    font-size: 12px;
                }
            }
        }

        .mainCentent {
            flex: 1 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #e8f8fe;
            border-radius: 5px;
            backdrop-filter: blur(5px);
            padding: 30px;

            .el-form {
                flex: 1 0 0;
            }

            .loginBtn {
                width: 100%;
                display: flex;

                .el-button {
                    flex: 1;
                }
            }
        }
    }
}
</style>