<template>
    <div class="home">
        <!-- home -->
        <el-container>
            <el-aside width="180px">
                <div class="asideHeader">
                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                    <div>
                        <div>峨眉旅业</div>
                        <div>维修管理系统</div>
                    </div>
                </div>
                <el-menu default-active="/home/VehiclesApproach" router unique-opened class="el-menu-vertical-demo"
                    @select="openSelect" style="border: none;">
                    <el-submenu v-for="v in navList" :key="v.index" :index="v.index">
                        <template slot="title">
                            <div class="menuTitle">
                                <i :class="v.icon"></i>
                                <span>{{ v.title }}</span>
                            </div>
                        </template>
                        <el-menu-item v-for="v2 in v.children" :key="v2.index" :index="'/home/' + v2.index"
                            style="background-color: #f7f7f7;">{{ v2.title }}</el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header>
                    <div>
                        <span>首页/{{ pathText }}</span>
                    </div>
                </el-header>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import router from '@/router';

export default {
    data() {
        return {
            navList: [
                {
                    title: "车辆维修",
                    icon: "el-icon-house",
                    index: "0",
                    children: [
                        {
                            title: "车辆进厂",
                            index: "VehiclesApproach"
                        },
                        {
                            title: "维修派工",
                            index: "RepairDispatch"
                        },
                        {
                            title: "维修过程",
                            index: "RepairProcess"
                        },
                        {
                            title: "出场检验",
                            index: "FactoryInspection"
                        }
                    ]
                },
                {
                    title: "数据统计",
                    icon: "el-icon-message",
                    index: "1",
                    children: [
                        {
                            title: "维修记录",
                            index: "MaintenanceRecords"
                        },
                        {
                            title: "维修异常",
                            index: "AbnormalRepairs"
                        },
                        {
                            title: "维修分析",
                            index: "MaintenanceAnalysis"
                        },
                        {
                            title: "维修项目",
                            index: "RepairItems"
                        },
                        {
                            title: "项目统计",
                            index: "ProjectStatistics"
                        },
                        {
                            title: "轮胎详情",
                            index: "TireDetails"
                        }
                    ]
                },
                {
                    title: "系统设置",
                    icon: "el-icon-user",
                    index: "2",
                    children: [
                        {
                            title: "维修工时",
                            index: "MaintenanceManHours"
                        },
                        {
                            title: "维修费用",
                            index: "RepairCosts"
                        },
                        {
                            title: "权限管理",
                            index: "RightsManagement"
                        }
                    ]
                }
            ],
            pathText: '车辆进厂'
        };
    },
    methods: {
        openSelect(path, paths, component) {
            // console.log(component.$el.innerText)
            this.pathText = component.$el.innerText;
        }
    },
    components: { router }
}
</script>
<style lang="less" scoped>
.home {
    height: 100vh;
    width: 100%;

    .asideHeader {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        &>div:last-child {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .menuTitle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.el-header {
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
}


.el-aside {
    background-color: #fff;
    color: #333;
    text-align: center;
}

.el-main {
    padding: 20px;
}

.home>.el-container {
    height: 100%;
}
</style>