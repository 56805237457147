import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/login.vue'
import home from '@/views/home/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: login,
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    children: [
      //车辆进厂
      {
        path: '',
        name: 'VehiclesApproach',
        component: () => import('@/views/home/VehicleMaintenance/VehiclesApproach/VehiclesApproach.vue')
      },
      {
        path: 'VehiclesApproach',
        name: 'VehiclesApproach',
        component: () => import('@/views/home/VehicleMaintenance/VehiclesApproach/VehiclesApproach.vue')
      },
      //维修派工
      {
        path: 'RepairDispatch',
        name: 'RepairDispatch',
        component: () => import('@/views/home/VehicleMaintenance/RepairDispatch/RepairDispatch.vue')
      },
      //维修派工详情
      {
        path: 'RepairDispatchDetail',
        name: 'RepairDispatchDetail',
        component: () => import('@/views/home/VehicleMaintenance/RepairDispatch/RepairDispatchDetail.vue')
      },
      //维修过程
      {
        path: 'RepairProcess',
        name: 'RepairProcess',
        component: () => import('@/views/home/VehicleMaintenance/RepairProcess/RepairProcess.vue')
      },
      //出厂检验
      {
        path: 'FactoryInspection',
        name: 'FactoryInspection',
        component: () => import('@/views/home/VehicleMaintenance/FactoryInspection/FactoryInspection.vue')
      },
      //维修记录
      {
        path: 'MaintenanceRecords',
        name: 'MaintenanceRecords',
        component: () => import('@/views/home/Statistics/MaintenanceRecords/MaintenanceRecords.vue')
      },
      //维修异常
      {
        path: 'AbnormalRepairs',
        name: 'AbnormalRepairs',
        component: () => import('@/views/home/Statistics/AbnormalRepairs/AbnormalRepairs.vue')
      },
      //维修分析
      {
        path: 'MaintenanceAnalysis',
        name: 'MaintenanceAnalysis',
        component: () => import('@/views/home/Statistics/MaintenanceAnalysis/MaintenanceAnalysis.vue')
      },
      //维修项目
      {
        path: 'RepairItems',
        name: 'RepairItems',
        component: () => import('@/views/home/Statistics/RepairItems/RepairItems.vue')
      },
      //项目统计
      {
        path: 'ProjectStatistics',
        name: 'ProjectStatistics',
        component: () => import('@/views/home/Statistics/ProjectStatistics/ProjectStatistics.vue')
      },
      //轮胎详情
      {
        path: 'TireDetails',
        name: 'TireDetails',
        component: () => import('@/views/home/Statistics/TireDetails/TireDetails.vue')
      },
      //维修工时
      {
        path: 'MaintenanceManHours',
        name: 'MaintenanceManHours',
        component: () => import('@/views/home/SystemSettings/MaintenanceManHours/MaintenanceManHours.vue')
      },
      //维修费用
      {
        path: 'RepairCosts',
        name: 'RepairCosts',
        component: () => import('@/views/home/SystemSettings/RepairCosts/RepairCosts.vue')
      },
      //权限管理
      {
        path: 'RightsManagement',
        name: 'RightsManagement',
        component: () => import('@/views/home/SystemSettings/RightsManagement/RightsManagement.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
